<template>
  <div
    :active-channel="active.channel"
    :active-channel-index="active.channelIndex"
    :active-one-category="active.oneCate"
    :active-channel-name="categoryData[active.channel] && categoryData[active.channel].channelName"
    class="c-new-category c-new-category__content--ccc j-new-category j-c-new-category__content--ccc"
    :class="{'zIndex': !isActive}"
    :style="{ top: layoutTop + 'rem' }"
  >
    <tab-header
      v-if="!isOneCate && !cat.tabLoadingStatus && !isPreview"
      ref="tabHeader"
      :type="cateType"
      :tab-data="categoryDataListByOrder"
      :active-key="active.channel"
      :category-crowd-info="categoryCrowdInfo"
      @selectedHandler="channelSelectedHandler"
    />
    <ClientOnly>
      <skeleton-tab v-if="cat.tabLoadingStatus" />
    </ClientOnly>

    <!-- 免邮政策组件 -->
    <div
      v-if="freeshippingContent && freeshippingContent.length"
      class="scrollfix-sub"
    >
      <AppCcc
        :content="freeshippingContent"
        :context="freeshippingContext"
        :scene-data="freeshippingSceneData"
      />
    </div>

    <category-new
      ref="categoryNew"
      :dynamic-cates="dynamicCates"
      :hande-touch-start="handeTouchStart"
      :translate-y-change="handleEventExpose"
      :toggle-expand="toggleExpand"
      :shadow-after-index="shadowAfterIndex"
      :shadow-animate="shadowAnimate"
      :shadow-after-height="shadowAfterHeight"
      :shadow-before-index="shadowBeforeIndex"
      :cate-type="cateType"
      :analysis="analysis"
      :is-preview="isPreview"
      :is-show-freeshipping="isShowFreeshipping"
      :next-page-prefix="NEXT_PAGE_PREFIX"
    />
  </div>
</template>

<script>
/* globals */
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { isSupportPassive, getQueryString } from '@shein/common-function'
import { branchCookie } from 'public/src/pages/common/banner/branchCookie.js'
// import { Slide } from '@shein/sui-mobile'
// components
import ClientOnly from 'vue-client-only'
import skeletonTab from './skeleton/header.vue'
// import skeletonCateLeft from './skeleton/category-left.vue'
// import skeletonCateRight from './skeleton/category-right.vue'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import categoryNew from './category_new.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
const daEventExpose = daEventCenter.getExposeInstance()

// mixin
import cateMixin from './mixin/ccc-cate-mixin'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { /*getUserAbtData,*/ abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
// import IScroll from 'public/src/pages/components/scroll/index.vue'
const isBrowser = typeof window !== 'undefined'
const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
const isIOS = !!(UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)

export default {
  name: 'CategoryContainer',
  components: {
    // IScroll,
    ClientOnly,
    skeletonTab,
    // skeletonCateLeft,
    // skeletonCateRight,
    // SSlide: Slide,
    AppCcc,
    categoryNew
  },
  mixins: [cateMixin],
  provide () {
    return {
      category: this,
      cateClassifyStore: () => {
        return {
          categoryData: this.categoryData,
          locals: this.locals,
          onAnalysis: ({ target }) => {
            this.analysis?.twoCateClickHandle?.({ target })
          }
        }
      },
      isIOS,
    }
  },

  asyncData ({ store, context }) {
    if (context && context.ssrPageType === 'category') {
      // ssr 客户端和服务端都会执行
      const data = JSON.parse(JSON.stringify(context))
      store.state['category'] = Object.assign(store.state['category'], data)
    }
  },

  data () {
    return {
      is_include_critical_css: typeof window !== 'undefined' ? gbCommonInfo.is_include_critical_css : true,
      cateType: 'cat',
      nextOneCateName: '',  // mixin中会进行赋值
      analysis: null,
      shadowAfterHeight: 'auto',
      shadowAfterIndex: undefined,
      shadowBeforeIndex: undefined,
      shadowAnimate: false,
      layoutTop: 0,
      heightTypes: [],
      rightScrollbars: false, // 是否显示滚动条
      NEXT_PAGE_PREFIX: 'nextPagePrefix',
      freeshippingHasData: true, // 免邮组件客户端请求是有数据显示的
      isActive: true
    }
  },
  computed: {
    ...mapState('category', ['dynamicCates', 'pageCode', 'locals', 'allTabAbt', 'ssrPageType', 'cateExpand', 'expandFirstLevelId', 'expandRearFirstLevel', 'categoryFreeshippingCcc', 'isNew', 'isPreview']),
    ...mapGetters('category', ['getCategoryFreeshippingCcc']),
    // canNextPage () {
    //   // return this.dynamicCates?.[this.active.channelIndex]?.pageDown && this.oneCategory?.[this.active.oneCate + 1]
    //   return this.categoryData?.[this.active.channel]?.pageDown && this.oneCategory?.[this.active.oneCate + 1]
    // },
    // 免邮组件数据
    freeshippingContent () {
      return this.getCategoryFreeshippingCcc?.freeshippingContent?.content || []
    },
    freeshippingContext () {
      return {
        lang: this.lang,
        language: this.getCategoryFreeshippingCcc?.freeshippingLanguage || {},
        PUBLIC_CDN: this.PUBLIC_CDN,
        ...this.locals
      }
    },
    freeshippingSceneData () {
      return {
        pageFrom: 'category',
        id: this.getCategoryFreeshippingCcc?.freeshippingContent?.id || '',
        pageType: 'category', // 埋点
        sceneName: 'all'
      }
    },
    isShowFreeshipping () {
      return this.getCategoryFreeshippingCcc?.hasFreeshippingComp && this.freeshippingHasData
    },
    isNewComp () {
      return this.categoryData[this.active.channel]?.isNew
    },
  },
  watch: {
    isShowFreeshipping (newValue) {
      if (!newValue) return
      // 判断当前policebanner是否存在占位逻辑。 如果存在 就干掉
      if (this.heightTypes.includes('policyBanner')) {
        this.heightTypes = this.heightTypes.filter(item => item !== 'policyBanner')
        this.handleCalcTopHeight()
      }

    }
  },
  beforeRouteEnter (to, from, next) {
    if (typeof window !== 'undefined') {
      window?.resourceSdkCase?.updateCutScene?.('category')
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (typeof window !== 'undefined') {
      window?.resourceSdkCase?.updateCutScene?.('')
    }
    next()
  },

  async created () {
    this.resetLocals()
    this.initCategoryTop() // 初始化分类顶部距离
    if (this.ssrPageType === 'category') {
      this.ssrInitCategoryData({ cateType: this.cateType })
    } else {
      await this.getCateList({ cateType: this.cateType, query: this.$route?.query || {} })
    }
    this.setMonitor()
    this.initHandle()
  },

  mounted () {
    this.handlePageWindowLayout()
    requestIdleCallback(() => {
      // 在首页预请求用户即将访问的其他页面的 js 资源
      this.prefetchResources()
    })
    setTimeout(() => {
      this.handeTouchStart()
    }, 1000)
  },

  async activated () {
    // 判断当前激活的频道是否有数据
    let channel = this.active?.channel || ''

    if(!channel){
      // 轮训等待 vuex的 getCateList action给 active.channel 赋好值后再进行获取频道
      let tryNum = 0, timer
      const awaitActive = () => {
        return new Promise(resolve => {
          timer = setInterval(() => {
            channel = this.active?.channel || ''
            tryNum++
            console.log('get active', tryNum)
            if(channel || tryNum > 10){
              clearInterval(timer)
              resolve()
            }
          }, 200)
        })
      }
      await awaitActive()
    }
    console.log('get active:channel=%s', channel)
    const child = this.categoryData?.[channel]?.child
    if (child?.length == 0) {
      await this.channelSelectedHandler({
        item: {
          channel_type: channel,
          index: this.active?.channelIndex,
          child
        },
        isManual: true
      })
    }
    if (this.initCateData) {
      this.activeTimer = setTimeout(() => {
        this.activeRefresh()
        this.initCategoryTop() 
      }, 500)
    }
    this.iosRepaint()
    // 禁止滚动条滚动
    document.addEventListener('touchmove', this.scrollStop, isSupportPassive() ? {
      capture: false,
      passive: false
    } : false)

    // 兼容单页带参跳转
    if (this.initCateData && this.active.channel !== '' && this.$route.query.channelId && this.$route.query.channelId != this.active.channel && this.categoryData[this.$route.query.channelId]) {
      if (this.$refs.tabHeader) {
        this.$refs.tabHeader.selectTab = this.$route.query.channelId
      }
      await this.channelSelectedHandler({
        item: {
          channel_type: this.$route.query.channelId,
          index: this.categoryData[this.$route.query.channelId].index,
          child: this.categoryData[this.$route.query.channelId].child
        },
        isManual: true
      })
    }

    requestIdleCallback(() => {
      this.checkBottomBar()
      if (!this.notFristPage) {
        this.notFristPage = true
      } else {
        // 二次进入页面才发送 pv
        this.analysis?.refreshAnalysisInfo?.()
        daEventExpose.resetAll?.(this.pageCode)
        this.handlePagePv()
      }
    })
  },
  deactivated () {
    // 销毁滚动条滚动
    document.removeEventListener('touchmove', this.scrollStop)
    // 边界滚动检测
    this.$refs.iScrollRight?.handleBoundary?.()
  },
  destroyed () {
    this.$store.unregisterModule('category')
  },
  methods: {
    ...mapMutations('category', [
      'assignStateCateExpand',
      'resetLocals',
    ]),
    ...mapActions('category', [
      'getCateList',
      'refreshCategoryCrowdInfo',
      'ssrInitCategoryData',
    ]),

    changeRouterPush(path) {
      markPoint('toNextPageClick', 'public')
      this.$router.push(path)
    },

    // 检查一下底部tab的状态是否触发现在在当前页面上，兼容全面屏返回时底部tab未更新的问题
    checkBottomBar () {
      const tabEl = document.querySelector('.j-index-tab-list-category')
      const activeClassName = 'title-active'
      if (tabEl && !tabEl.classList.contains(activeClassName)) { // 删除其他tab的选中状态，添加当前tab的选中状态
        [...tabEl.parentNode.children].forEach(item => {
          if (item !== tabEl) {
            item.classList.remove(activeClassName)
          } else {
            item.classList.add(activeClassName)
          }
        })
      }
    },

    activeRefresh () {
      this.$nextTick(() => {
        this.$refs.iScrollLeft?.refresh?.(false)
        this.$refs.iScrollRight?.refresh?.(false)
      })
    },

    prefetchResources () {
      // el 必须是渲染完的
      prefetchResource.listen({
        el: this.$el,
        delay: 2000, // 默认两秒
        prefetchList: [
          {
            chunkName: 'plv2_container',
            relType: 'prefetch'
          },
          // 个人中心子路由容器
          {
            chunkName: 'user',
            relType: 'prefetch'
          },
          {
            chunkName: 'user-index',
            relType: 'prefetch'
          },
        ],
      })

      setTimeout(() => {
        const loadComponent = prefetchResource.importAsyncComponent({
          chunkName: 'plv2_container', // 跟webpackChunkName保持一致
          componentFactory: () => import( /* webpackChunkName: 'plv2_container' */ 'public/src/pages/product_list_v2/container.vue'),
        })
        loadComponent?.()
      }, 2000)
    },

    initHandle () {
      this.handleNextOneCateName() // 初始化下一个一级分类名称 mixin.js
      if (typeof window === 'undefined') return
      setTimeout(async () => {
        this.initAnalysis()
      }, 500)
    },

    initAnalysis () {
      import(/* webpackChunkName: "category-analysis" */ './analysis').then(async (module) => {
        let analysis = module.default
        this.analysis = new analysis({
          container: 'j-c-new-category__content--ccc',
          categoryInfo: this.dynamicCates,
          categoryCrowdInfo: this.categoryCrowdInfo,
          isAllTabAutoMap: this.isAllTabAutoMap,
          cateType: this.cateType
        })
        await this.analysis.initSaModule()
        this.handlePagePv()
      })
    },

    handleEventExpose () {
      // this.handleResize()
      if (!this.analysis?.eventExpose) return
      this.analysis.eventExpose()
    },

    initCategoryTop () {
      const isSsrPage = this.ssrPageType === 'category'
      this.heightTypes = ['header']
      const isServer = typeof window === 'undefined'

      if (isSsrPage) {
        if (this.locals.showBranch) {
          this.heightTypes.push('branch')
        }
      } else if (!isServer) { // 切页面进行判断branch是否显示
        const { cookieValue = '' } = branchCookie() || {}
        if (cookieValue && cookieValue.includes('/category')) {
          this.heightTypes.push('branch')
        }
      }

      if (isServer && this.locals?.hasTopBanner) {
        this.heightTypes.push('topBanner')
      } else if (!isServer) {
        // window.sheinBanner.topBanner.Local.setFlag 这个值只有从首页跳转过来的时候有
        const changeRouterPage = window.sheinBanner?.topBanner?.Local.setFlag && window.sheinBanner?.topBanner?.Local.setFlag !== 'hide' // 会存在setFlag为hide的情况
        const clientFirstPage = !window.sheinBanner?.topBanner?.Local.setFlag && this.locals?.hasTopBanner

        if (changeRouterPage || clientFirstPage) {
          this.heightTypes.push('topBanner')
        }
      }
      // 初始化为空，刷新后
      if ((this.locals?.hasPolicyBanner && !this.isShowFreeshipping) || (!isServer && window.sheinBanner?.policyBanner?.Local.setFlag)) {
        this.heightTypes.push('policyBanner')
      }
      this.handleCalcTopHeight()
    },

    handleCalcTopHeight () {
      const heightMap = {
        branch: 2.0267,    // branch component
        header: 1.17,      // header component
        topBanner: 1.04,   //topBanner component
        policyBanner: 1.31368  // policyBanner component
      }
      this.layoutTop = this.heightTypes.reduce((total, type) => {
        total += heightMap[type] || 0
        return total
      }, 0)
    },

    handlePageWindowLayout () {
      const changeLayout = ({ type, value }) => {
        if (this.$route.name !== 'page_category') return

        if (value && !this.heightTypes.includes(type)) {
          this.heightTypes.push(type)
        }
        if (!value) {
          this.heightTypes = this.heightTypes.filter(item => item !== type)
        }
        const beforeLayoutTop = this.layoutTop
        this.handleCalcTopHeight()
        if (beforeLayoutTop !== this.layoutTop) { // 高度发生变化
          this.handleLayout()
        }
      }

      const handleBranchShow = () => {
        changeLayout({ type: 'branch', value: true })
      }
      const handleBranchHide = () => {
        changeLayout({ type: 'branch', value: false })
      }
      const handleCccBannerShow = ({ type = 'topBanner' } = {}) => {
        changeLayout({ type, value: true })
      }
      const handleCccBannerHide = ({ type } = {}) => {
        changeLayout({ type, value: false })
      }

      const handleWindowResize = () => {
        // 新人注册弹窗进入输入状态时会滑动到底部，需要重置滚动条
        if (this.$route.name !== 'page_category') return
        document.scrollingElement.scrollTop = 0
      }

      window.vBus.$on('onBranchHide', handleBranchHide)
      window.vBus.$on('onBranchShow', handleBranchShow)
      appEventCenter.$on('cccBannerShow', handleCccBannerShow)
      appEventCenter.$on('cccBannerHide', handleCccBannerHide)
      appEventCenter.$on('quickRegisterDialogEvent', handleWindowResize)
      this.onFreeshippingData()
      this.$once('hook:beforeDestroy', () => {
        window.vBus.$off('onBranchHide', handleBranchHide)
        window.vBus.$off('onBranchShow', handleBranchShow)
        appEventCenter.$off('cccBannerShow', handleCccBannerShow)
        appEventCenter.$off('cccBannerHide', handleCccBannerHide)
        appEventCenter.$off('quickRegisterDialogEvent', handleWindowResize)
        cccxEventBus?.off('ccc-freeShippingComp-noDataNeedHide')
      })
      setTimeout(() => {
        appEventCenter.$on('refreshCategoryCrowdInfo', () => this.refreshCategoryCrowdInfo({ cateType: this.cateType }))
      })
    },

    toggleExpand ({ cate }) {
      if (!cate.expandChild) return
      this.assignStateCateExpand({ [cate.id]: cate.expand })
      this.$nextTick(() => {
        this.$refs.iScrollRight?.refresh?.(true)
      })
    },

    /* 切换tab进行计算高度 */
    handleLayout ({ isChangeChannel = true, isChangeOneCate, nextPage } = {}) {
      if (!this.$refs.iScrollLeft) return
      daEventExpose.resetAll?.(this.NEXT_PAGE_PREFIX)
      if (nextPage) {
        this.handleShadowAnimate()
        this.$refs.iScrollRight?.refresh?.(true)
        return
      }
      if (isChangeChannel) {
        this.$refs.iScrollLeft?.refresh?.(true)
        this.$refs.iScrollRight?.refresh?.(true)
        this.handleShadowAnimate()
      } else if (isChangeOneCate) {
        this.$refs.iScrollRight?.refresh?.(true)
      }
    },

    async initShadow () {
      if (this.rightScrollbars) return
      // const { NaviAllTab } = await getUserAbtData()
      // if (!NaviAllTab?.param?.match?.(/^type=on/)) return
      this.rightScrollbars = true // 显示滚动条
    },

    handeTouchStart () {
      // 判断当前是否从其他页面进行返回了。 如果返回的重新计算一次高度
      if (this.touchInit) return
      this.touchInit = true
      this.scrollStartEvent() // 触摸左侧，快速注册弹窗才进行注册
      this.initShadow()
    },
    //ios 激活时需要更改下css visibility 防止页面左侧空白
    iosRepaint () {
      if (isIOS) {
        // 更改定位方式，强制重绘
        this.isActive = false
        this.$nextTick(() => {
          requestAnimationFrame(() => {
            this.isActive = true
          })
        })
      }
    },

    // 处理翻页
    async handleBoundary ({ direction }) {
      if (!this.canNextPage) return
      if (direction !== 'ttb') return
      const oneCategoryIndex = this.active.oneCate + 1
      await this.oneCategorySelectedHandler({ oneCategoryIndex, nextPage: true })
      daEventCenter.triggerNotice({
        daId: '1-14-1-9',
        extraData: {
          channelActiveClass: '.j-sa-active-topcategory',
          oneCateActiveClass: '.j-sa-active-one', // 报上一个左侧类目
        }
      })
    },

    /* 过渡动画 */
    handleShadowAnimate () {
      this.$refs.iScrollLeft.updateHeight()
      const beforeElement = this.active.oneCate > 0 ? this.$refs.iScrollLeft.$el.querySelector(`.cate-new-left`).children[this.active.oneCate - 1] : {}
      const y = beforeElement.offsetTop || 0
      this.$refs.iScrollLeft.scrollTo({ y, time: 300 })
    },

    scrollStartEvent () {
      if (window.gbRegisterModule) {
        const children = window.gbRegisterModule.$children
        const quickRegister = children.find(item => item.showMiniRegister)
        quickRegister && quickRegister.showMiniRegister()
      }
    },

    handlePagePv () {
      const pagefrom = getQueryString({ key: 'pf' })
      const src_module = getQueryString({ key: 'src_module' }) || '-'
      const src_identifier = getQueryString({ key: 'src_identifier' }) || '-'
      const src_tab_page_id = getQueryString({ key: 'src_tab_page_id' }) || '-'
      let page_param = {
        src_module,
        src_identifier,
        src_tab_page_id
      }
      if (this.categoryCrowdInfo && this.categoryCrowdInfo.crowdAbt && this.categoryCrowdInfo.crowdAbt.p === 'type=A' && this.categoryCrowdInfo.crowdId) {

        const info = abtUserAnalysis({ posKeys: 'crowdAbt', abtInfo: this.categoryCrowdInfo })

        page_param.tab_crowd_id = this.categoryCrowdInfo.crowdId
        page_param.abtest = info.sa
      }

      // FR-7889 补充统计参数 pagefrom
      if (pagefrom) {
        page_param.pagefrom = pagefrom
      }

      window.SaPageInfo = {
        page_id: 9,
        page_name: 'page_category',
        page_param,
        start_time: Date.now()
      }
      window.PageGroupOverview = '首页'
      appEventCenter.$emit('pageOnload')
      this.handleEventExpose() // 发曝光
    },

    onFreeshippingData () {
      cccxEventBus?.on('ccc-freeShippingComp-noDataNeedHide', () => {
        this.freeshippingHasData = false
      })
    },
    setMonitor() {
      if(typeof window === 'undefined') return
      metricPageSuccess({ 
        page: 'page_category',
        status: (this.oneCategory?.length && this.twoCategory?.length) ? '1' : '0',
      })
    }

  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  &__content--ccc {
    position: fixed;
    top: 3.17rem;
    bottom: 50px;
    width: 10rem;
    background: #f6f6f6;
    transform: translate3d(0, 0, 0);
    a {
      text-decoration: none;
    }
    .cate-one {
      &__shadow-animate {
        &:before,
        .cate-one__shadow-after {
          transition: opacity 1s;
          opacity: 0;
        }
      }
      &__shadow-before {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 2.7733rem;
          z-index: @zindex-hack;
          background: #f6f6f6;
          cursor: none;
        }
      }
      &__shadow-after {
        position: absolute;
        bottom: 0.3467rem;
        top: 0;
        left: 0;
        width: 2.7733rem;
        z-index: @zindex-hack;
        background-image: linear-gradient(180deg, rgba(246, 246, 246, 0.0001) 0%, #f6f6f6 100%);
        cursor: none;
      }
    }
  }
  .iScrollVerticalScrollbar {
    position: absolute;
    z-index: @zindex-hack;
    width: 3px;
    bottom: 0;
    top: 0;
    right: 3px;
    opacity: 0;
    &_show {
      opacity: 1;
    }
  }
  .iScrollIndicator {
    box-sizing: border-box;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 1.5px;
    width: 100%;
  }
}

</style>
